"use client";

import { LabelLink } from "@/components/LabelLink/LabelLink";
import { BrokenBulb } from "@/components/icons/BrokenBulb";
import { trackException } from "@/lib/appinsights";
import clsx from "clsx";
import { useLocale } from "next-intl";
import { useEffect } from "react";

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  const locale = useLocale();
  const { title, buttonLabel } = getTranslatedText({ locale });

  // Set the dark mode or light mode theme
  useEffect(() => {
    try {
      const preferredTheme = localStorage.getItem("theme");
      document.documentElement.dataset.theme = preferredTheme || "dark";
    } catch (err) {}
  });

  useEffect(() => {
    trackException(error);
  }, [error]);

  return (
    <main
      className={clsx(
        "min-h-screen bg-lightestGrey text-primaryBlack transition-colors selection:bg-primaryOrange",
        "dark:bg-darkBlue dark:text-white",
        "flex justify-center",
      )}
    >
      <section
        className={clsx(
          "px-4",
          "mt-40",
          "flex flex-col items-center gap-4",
          "lg:maxContentWidth w-full",
        )}
      >
        <h1 className="text-3xl md:text-7xl">{title}</h1>
        <BrokenBulb className="md:h-[500px] md:w-[500px]" />
        <LabelLink href="/" ariaLabel={buttonLabel}>
          {buttonLabel}
        </LabelLink>
      </section>
    </main>
  );
}

function getTranslatedText({ locale }: { locale: string }) {
  if (locale === "en") {
    return {
      title: "Something went wrong!",
      buttonLabel: "Home",
    };
  }
  return {
    title: "Något gick fel!",
    buttonLabel: "Hem",
  };
}
