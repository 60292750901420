import { ArrowRight } from "../icons/ArrowRight";
import { MLink } from "../MLink/MLink";
import { ReactNode } from "react";
import clsx from "clsx";

interface LabelLinkProps {
  href: string;
  ariaLabel: string;
  children: ReactNode;
  locale?: string;
  variant?: "primary" | "secondary";
}

export function LabelLink({
  href,
  ariaLabel,
  children,
  locale,
  variant = "primary",
}: LabelLinkProps) {
  const className = getClassName(variant);
  const arrow = getArrow(variant);
  return (
    <MLink
      href={href}
      ariaLabel={ariaLabel}
      className={className}
      locale={locale}
    >
      <div className={clsx("flex")}>
        {children}
        {arrow}
      </div>
    </MLink>
  );
}

function getClassName(variant: string) {
  const textSizeClass = variant === "secondary" ? "buttonsSmall" : "buttons";
  return clsx("dark:text-white", textSizeClass);
}

function getArrow(variant: string) {
  if (variant === "primary") {
    return (
      <div>
        <ArrowRight
          className={clsx(
            "ml-2.5",
            "pr-2.5",
            "md:inline-block",
            "hidden",
            "text-primaryOrange",
          )}
          size="M"
        />
        <ArrowRight
          className={clsx(
            "md:hidden",
            "d-md-none",
            "ml-2.5 ",
            "pr-2.5",
            "text-primaryOrange",
          )}
          size="S"
        />
      </div>
    );
  }

  return (
    <div>
      <ArrowRight
        className={clsx(
          "ml-2.5",
          "pr-2.5",
          "md:inline-block",
          "hidden",
          "text-primaryOrange",
        )}
        size="S"
      />
      <ArrowRight
        className={clsx(
          "md:hidden",
          "d-md-none",
          "ml-2.5 ",
          "pr-2.5",
          "text-primaryOrange",
        )}
        size="XS"
      />
    </div>
  );
}
