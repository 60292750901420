import {
  ApplicationInsights,
  ICustomProperties,
  ITelemetryItem,
} from "@microsoft/applicationinsights-web";

const testAppInsightsConnectionString =
  "InstrumentationKey=2ef1d56b-ac30-4d54-a047-3cd0dd40ddde;IngestionEndpoint=https://westeurope-1.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/;ApplicationId=ac9aacdd-5132-4689-b58e-8ab83d635886";
const productionAppInsightsConnectionString =
  "InstrumentationKey=884000f4-bb68-4d61-a059-7bfb849a92e0;IngestionEndpoint=https://westeurope-1.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/;ApplicationId=61e12bb4-884e-4101-b160-b7016bb8d3a3";
// "InstrumentationKey=884000f4-bb68-4d61-a059-7bfb849a92e0;IngestionEndpoint=https://westeurope-1.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/;ApplicationId=61e12bb4-884e-4101-b160-b7016bb8d3a3"

let appInsightsConnectionString = testAppInsightsConnectionString;

if (
  process.env.SYSTEM_ENVIRONMENT === "production" ||
  (typeof window !== "undefined" && window.location.host === "www.modity.se")
) {
  appInsightsConnectionString = productionAppInsightsConnectionString;
}

const appInsights = new ApplicationInsights({
  config: {
    connectionString: appInsightsConnectionString,
    enableAutoRouteTracking: true,
    disableCookiesUsage: true,
  },
});

appInsights.loadAppInsights();
appInsights.trackPageView(); // Tracks initial page load
appInsights.addTelemetryInitializer((item: ITelemetryItem) => {
  if (item.tags) {
    item.tags["ai.cloud.role"] = "Modity.se";
  }
});

export const trackException = (exception: Error, severityLevel?: number) => {
  console.log("Exception: ", exception);
  appInsights.trackException({ exception, severityLevel });
};

export const trackTrace = (
  message: string,
  properties?: { [key: string]: ICustomProperties | undefined },
) => {
  appInsights.trackTrace({ message }, properties);
};

export const trackEvent = (
  name: string,
  properties?: { [key: string]: ICustomProperties | undefined },
) => {
  appInsights.trackEvent({ name }, properties);
};

export const SEVERITY_LEVELS = {
  VERBOSE: 0,
  INFORMATION: 1,
  WARNING: 2,
  ERROR: 3,
  CRITICAL: 4,
};

// export a component so we can load this in layout.tsx
export const AzureAppInsights = () => {
  return <></>;
};
